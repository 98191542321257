import {createNamespacedHelpers} from 'vuex'
import {cloneDeep, get, isArray, isEmpty, isEqual, isNull, isObject, isUndefined} from 'lodash'
import {notify, xAjax} from '@plugins/MercuryBaseLib'
import {getFileServletUrl} from '@plugins/CapsuleCommon'
import {fileSize, getFileExtension} from '@plugins/MercuryBaseUtils'

const baseHelper = createNamespacedHelpers('base')

const capsuleBaseMixin = {
    data: function () {
        return {
            minimizedDialogs: new Set(),
            moneyInputOptions: {
                locale: 'ko-KR',
                prefix: '',
                suffix: '',
                length: 13,
                precision: 3
            },
            feeInputOptions: {
                locale: 'ko-KR',
                prefix: '',
                suffix: '',
                length: 8,
                precision: 3
            },
            platformAdProducts: {},
            creativeImageSizeStandard: {
                'DV360': function (width, height) {
                    const dimension = `${width}x${height}`
                    const standard = [
                        '120x600',
                        '160x600',
                        '180x150',
                        '250x250',
                        '300x250',
                        '300x600',
                        '320x50',
                        '320x100',
                        '320x480',
                        '468x60',
                        '728x90',
                        '768x1024'
                    ]
                    const valid = standard.includes(dimension)
                    return {
                        valid: valid,
                        msg: valid ? null : `가능한 사이즈: ${standard}`
                    }
                },
                'FACEBOOK_facebook-feed': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 600픽셀
                        최소 높이: 600픽셀
                        화면 비율 허용 범위: 3%
                    * */

                    const valid = width >= 600 && height >= 600 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 600, 최소 높이: 600, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_facebook-right-hand-column': function (width, height, size = 0) {
                    /*
                    *
                        최소 너비: 254 픽셀
                        최소 높이: 133픽셀
                        화면 비율 허용 범위: 3%
                    * */

                    const valid = width >= 254 && height >= 133
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 254, 최소 높이: 133`
                    }
                },
                'FACEBOOK_facebook-instant-article': function (width, height, size = 0) {
                    /*
                    *
                        최대 파일 크기: 30MB
                    * */

                    const valid = size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_facebook-instream-video': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 600픽셀
                        최소 높이: 600픽셀
                        화면 비율 허용 범위: 3%
                    * */

                    const valid = width >= 600 && height >= 600 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 600, 최소 높이: 600, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_facebook-marketplace': function (width, height, size = 0) {
                    /*
                    *
                        최대 파일 크기: 30MB
                    * */

                    const valid = size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_facebook-story': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 500픽셀
                        화면 비율 허용 범위: 1%
                    * */

                    const valid = width >= 500 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 500, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_facebook-search': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 600픽셀
                        최소 높이: 600픽셀
                        화면 비율 허용 범위: 3%
                    * */

                    const valid = width >= 600 && height >= 600 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 600, 최소 높이: 600, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_facebook-biz-disco-feed': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 600픽셀
                        최소 높이: 600픽셀
                        화면 비율 허용 범위: 3%
                    * */

                    const valid = width >= 600 && height >= 600 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 600, 최소 높이: 600, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_facebook-facebook-reels-overlay': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 600픽셀
                        최소 높이: 600픽셀
                        화면 비율 허용 범위: 3%
                    * */

                    const valid = width >= 600 && height >= 600 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 600, 최소 높이: 600, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_instagram-story': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 500픽셀
                        화면 비율 허용 범위: 1%
                    * */

                    const valid = width >= 500 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 500, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_instagram-feed': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                    *   최소 너비: 500픽셀
                        최소 화면 비율: 400x500픽셀 (0.8)
                        최대 화면 비율: 191x100픽셀 (1.91)
                        화면 비율 허용 범위: 1%
                    * */

                    const ratio = width / height
                    const valid = width >= 500 && size <= 31457280 && (ratio >= 0.8 && ratio <= 1.91)
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 500, 최소 화면 비율: 400x500, 최대 화면 비율: 191x100, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_instagram-explore': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 500픽셀
                        화면 비율 허용 범위: 1%
                    * */

                    const valid = width >= 500 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 500, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_audience-network-native': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 398픽셀
                        최소 높이: 208픽셀
                    * */

                    const valid = width >= 398 && height >= 208 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 398, 최소 높이: 208, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_messenger-sponsored-messages': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                    * */

                    const valid = size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_messenger-home': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 254픽셀
                        최소 높이: 133픽셀
                    * */

                    const valid = width >= 254 && height >= 133 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 254, 최소 높이: 133, 최대 파일 크기: 30MB`
                    }
                },
                'FACEBOOK_messenger-story': function (width, height, size = 0) {
                    /*
                    *   최대 파일 크기: 30MB
                        최소 너비: 500픽셀
                    * */

                    const valid = width >= 500 && size <= 31457280
                    return {
                        valid: valid,
                        msg: valid ? null : `최소 너비: 500, 최대 파일 크기: 30MB`
                    }
                }
            }

        }
    },
    created() {

        const cachedPlatformAdProducts = this.getPlatformAdProducts

        if (isObject(cachedPlatformAdProducts) && !isEmpty(cachedPlatformAdProducts)) {
            this.platformAdProducts = cloneDeep(cachedPlatformAdProducts)
        } else {
            xAjax({
                url: '/campaign/platformAdProducts',
                method: 'GET'
            }).then(resp => {
                console.log('platformAdProducts', resp)
                this.platformAdProducts = resp
                this.$setPlatformAdProducts(resp)
            })
        }

    },
    filters: {
        currency(value) {
            return `$ ${value}`
        }
    },
    computed: {
        ...baseHelper.mapGetters([
            'getClients',
            'getClientsMap',
            'getOrganizationTree',
            'getUser',
            'getActiveEmployees',
            'getBadge',
            'getChatTagOptions',
            'getPlatformAdProducts',
            'getAdPlatforms',
            'getAdConfigs',
            'getUnmanagedPlatforms',
            'getEnableSchedule'
        ]),
        campaignAdCustomValueKeys(platform, subType) {
            return [
                {text: '키워드', value: 'targetKeyword', type: 'spreadsheet'},
                {text: '노출기기', value: 'targetDevice'},
                {text: '노출지면', value: 'targetArea'},
                {text: '지역', value: 'targetRegion'},
                {text: '목표', value: 'optimize'},
                {text: '직접입력', value: ''},
                {text: 'Spread Sheet', value: 'spreadsheet', type: 'spreadsheet'},
                {text: 'File', value: 'file', type: 'file'}
            ]
        }

    },
    methods: {
        ...baseHelper.mapActions({
            $setChatTagOptions: 'setChatTagOptions',
            $setPlatformAdProducts: 'setPlatformAdProducts'
        }),

        $getFileServletUrl: getFileServletUrl,
        $isArray: isArray,
        $copy(text, $container) {
            if (!$container) {
                $container = undefined
            }
            this.$copyText(text, $container).then(r => {
                notify('복사 되었습니다.', {}, {type: 'info'})
            })

        },
        $getCreativeGroupCustomValueKeys(clientId, platformId, subType) {

            let list = []
            let platform = null

            const platforms = this.$getPlatforms(clientId) || []


            const target = platforms.find(e => e.id === platformId || e.platform === platformId)

            platform = target.platformName

            if (platform === 'DV360') {
                if (subType === 'VIDEO') {
                    list.push(
                        {text: '클릭유도문안', value: '클릭유도문안'}
                        , {text: '광고제목', value: '광고제목'}
                    )

                }
            } else if (platform === 'FACEBOOK') {
                list.push(
                    {
                        text: '노출 위치', value: '노출 위치'
                        , options: [
                            {'text': 'Facebook 피드', 'value': 'facebook-feed'},
                            {'text': 'Facebook 오른쪽 칼럼', 'value': 'facebook-right-hand-column'},
                            {'text': 'Facebook 인스턴트 아티클', 'value': 'facebook-instant-article'},
                            {'text': 'Facebook 인스트림 동영상', 'value': 'facebook-instream-video'},
                            {'text': 'Facebook Marketplace', 'value': 'facebook-marketplace'},
                            {'text': 'Facebook 스토리', 'value': 'facebook-story'},
                            {'text': 'Facebook 검색 결과', 'value': 'facebook-search'},
                            {'text': 'Facebook 비즈니스 둘러보기', 'value': 'facebook-biz-disco-feed'},
                            {'text': 'Facebook 릴스 오버레이', 'value': 'facebook-facebook-reels-overlay'},
                            {'text': 'Instagram 스토리', 'value': 'instagram-story'},
                            {'text': 'Instagram 피드', 'value': 'instagram-feed'},
                            {'text': 'Instagram 탐색 탭', 'value': 'instagram-explore'},
                            {'text': 'Audience Network 네이티브, 배너 및 삽입광고', 'value': 'audience-network-native'},
                            {'text': '홍보 메세지', 'value': 'messenger-sponsored-messages'},
                            {'text': 'Messenger 받은 메세지함', 'value': 'messenger-home'},
                            {'text': 'Messenger 스토리', 'value': 'messenger-story'}
                        ]
                    },
                    {text: '문구', value: '문구'},
                    {text: '타이틀', value: '타이틀'},
                    {text: 'CTA', value: 'CTA'},
                    /*{text: '목표', value: '목표'},*/
                    {text: '타겟', value: '타겟'}
                )
            } else if (platform === 'GADS') {
                list.push(
                    {text: '제목', value: '제목'}
                    , {text: '설명', value: '설명'}
                    , {text: '유도문안', value: '유도문안'}
                    , {text: '업체명', value: '업체명'}
                )
            } else if (platform === 'SA360') {
                list.push(
                    {text: 'adHeadline', value: 'adHeadline'}
                    , {text: 'adHeadline2', value: 'adHeadline2'}
                    , {text: 'adHeadline3', value: 'adHeadline3'}
                    , {text: 'adDescription1', value: 'adDescription1'}
                    , {text: 'adDescription2', value: 'adDescription2'}
                )
            }

            const defaultList = [
                {text: 'Spread Sheet', value: 'spreadsheet', type: 'spreadsheet'},
                {text: '직접입력', value: ''}
            ]

            return [
                ...list,
                ...defaultList
            ]
        },
        $getCustomValueType(value) {
            const target = this.campaignAdCustomValueKeys.find(e => e.value === value)

            if (target) {
                const options = target.options
                if (isArray(options) && options.length > 0) {
                    return 'list'
                }
                return target.type || 'text'
            }
            return 'text'
        },
        $getCreativeCustomValueType(creativeGroupCustomValueKeys, value) {
            const target = creativeGroupCustomValueKeys.find(e => e.value === value)

            if (target) {
                const options = target.options
                if (isArray(options) && options.length > 0) {
                    return 'list'
                }
                return target.type || 'text'
            }
            return 'text'
        },
        $getCreativeCustomValueOptions(creativeGroupCustomValueKeys, value) {
            const target = creativeGroupCustomValueKeys.find(e => e.value === value)
            if (target) {
                const options = target.options
                if (isArray(options)) {
                    return options
                }
                return []
            }
            return []
        },
        $isEmpty(obj) {
            return isEmpty(obj)
        },
        $getTicketStatusIcon(status) {
            if (status === 'REQUEST') {
                return 'mdi-send'
            }
            if (status === 'PROCESSING') {
                return 'mdi-check-all'
            }
            if (status === 'REJECT') {
                return 'mdi-account-cancel'
            }
            if (status === 'COMPLETE') {
                return 'mdi-star'
            }
            if (status === 'RETRIEVE') {
                return 'mdi-restore'
            }
        },
        $getTicketStatusColor(status) {
            if (status === 'REQUEST') {
                return 'orange'
            }
            if (status === 'PROCESSING') {
                return 'primary'
            }
            if (status === 'REJECT') {
                return 'pink'
            }
            if (status === 'COMPLETE') {
                return 'green'
            }
            if (status === 'RETRIEVE') {
                return 'cyan'
            }
            if (status === 'PENDING') {
                return 'orange'
            }
        },
        $getUserById(id) {
            return this.getActiveEmployees.find(e => e.id === id)
        },
        $getPlatformSubType(clientId = null, platformId) {
            if (!platformId) {
                return null
            }
            const platforms = this.$getPlatforms(clientId)
            const target = platforms.find(e => e.id === platformId || e.platform === platformId)
            if (!target) {
                return null
            }

            if (target.platformName === 'DV360') {
                return [
                    {'text': 'DISPLAY', 'value': 'DISPLAY'},
                    {'text': 'VIDEO', 'value': 'VIDEO'}
                ]
            }

            return null
        },
        $getUserSimpleLabel(id) {
            const user = this.$getUserById(id)
            if(!user) {
                return `unknownUserId-${id}`;
            }
            return `${user.name}(${user.nickname})`
        },
        $getPlatformOptions(clientId = null, hasAll = true, valueKey = 'platform', valueText = 'platformName') {
            const platforms = this.$getPlatforms(clientId).filter(e =>
                e.conversion !== true
                || (e.optionJson && (e.optionJson['cm_billing'] === true || e.optionJson['sa_billing'] === true))
                || (clientId === null && (e.platform === 'CM' || e.platform === 'SA360')))
            const appendOptions = hasAll ? [{value: null, text: 'ALL'}] : []

            return [...appendOptions, ...platforms.map(e => ({
                text: e[valueText],
                value: e[valueKey],
                platform: e.platform,
                platformId: e.platformId,
                platformSource: e.platformSource
            }))]
        },
        $getConversionPlatformOptions(clientId = null, hasAll = true, valueKey = 'platform', dspPlatform = null) {
            const platforms = this.$getPlatforms(clientId).filter(e => e.conversion === true)
            const appendOptions = hasAll ? [{value: null, text: 'ALL'}] : []

            if (isObject(dspPlatform)) {

                if (dspPlatform.text === 'FACEBOOK' || dspPlatform.text === 'GADS' || dspPlatform.text === 'SA360') {
                    appendOptions.push({
                        text: dspPlatform.text,
                        value: dspPlatform.value
                    })
                }
            }

            return [...appendOptions, ...platforms.map(e => ({
                text: e.platformName,
                value: e[valueKey]
            }))]
        },
        $getClient(clientId = null) {
            return this.getClients.find(e => e.id === clientId)
        },
        $getPlatforms(clientId = null) {
            const clients = this.getClients
            const clientsMap = this.getClientsMap

            if (clientId == null || clientId == -1) {
                const platforms = []

                clients.forEach(client => {
                    const _platforms = client.platforms
                    _platforms.forEach(_platform => {
                        if (!platforms.find(platform => platform.platform === _platform.platform)) {
                            platforms.push(_platform)
                        }
                    })
                })

                return platforms.map(e => ({
                    platform: e.platform,
                    platformName: e.platformName,
                    conversion: e.conversion,
                    platformSource: e.platformSource
                }))

            }
            return clientsMap[clientId].platforms
        },
        $getPlatform(clientId, platformId) {
            const platforms = this.$getPlatforms(clientId) || []
            return platforms.find(e => e.id === platformId || e.platform === platformId)
        },
        $getAdPlatformProducts(clientId, platformId) {

            const adPlatforms = this.getAdPlatforms
            if (!adPlatforms) {
                return []
            }

            const defaultProducts = adPlatforms['COMMON'] ? (adPlatforms['COMMON'].adPlatformProducts || []) : []
            const platform = this.$getPlatform(clientId, platformId)

            if (!platform) {
                return [...defaultProducts]
            }

            const platformName = platform.platform
            if (!platformName) {
                return [...defaultProducts]
            }

            if (!adPlatforms[platformName]) {
                return [...defaultProducts]
            }

            return [...adPlatforms[platformName].adPlatformProducts, ...defaultProducts]
        },
        $getAdPlatformProduct(id) {
            const adPlatforms = this.getAdPlatforms
            let target = null
            for (const name in adPlatforms) {
                adPlatforms[name].adPlatformProducts.forEach(e => {
                    if (e.id === id) {
                        target = e
                        return false
                    }
                })
            }
            return target
        },
        $getAdPlatformProductOptions(clientId, platformId) {
            return this.$getAdPlatformProducts(clientId, platformId).map(e => ({
                text: e.name,
                value: e.id
            }))
        },
        $getAdConfigOptions(clientId, platformId, productId = null, type = 'AD', containsPaused = false) {
            const platform = this.$getPlatform(clientId, platformId)
            const adPlatforms = this.getAdPlatforms
            const defaultOptions = []
            const commonPlatform = adPlatforms['COMMON']
            if (commonPlatform && isArray(commonPlatform.adConfigs)) {
                defaultOptions.push(...commonPlatform.adConfigs)
            }


            if (!platform) {
                return defaultOptions
            }

            const platformName = platform.platform
            if (!platformName) {
                return defaultOptions
            }


            const adPlatform = adPlatforms[platformName]

            if (!adPlatform) {
                return defaultOptions
            }

            const adPlatformProducts = adPlatform.adPlatformProducts
            const adConfigs = adPlatform.adConfigs

            const result = [...adConfigs]

            if (productId) {
                const targetProduct = adPlatformProducts.find(e => e.id === productId)
                if (targetProduct && isArray(targetProduct.adConfigs)) {
                    result.push(...targetProduct.adConfigs)
                }
            }

            return [...result, ...defaultOptions].filter(e => e.configLevel === type && (containsPaused || e.status === 'ACTIVE'))

        },
        $downloadImage(file) {
            console.log('downloadImage', file)
            location.href = `${this.$settings.requestMapping}/file/download/${file.fileKey}`
        },
        $getFileExtension: getFileExtension,
        $fileSize: fileSize
    }
}

export default capsuleBaseMixin
